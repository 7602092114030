<template>
  <div>
    <div class="login-bg" v-if="searchInfo.isShowPage">
      <LoginHeader>欢迎登录</LoginHeader>
      <section class="login-section">
        <div class="wrapper">
          <nav>
            <a :class="{ active: true }" href="javascript:;" v-if="activeName === 'login'">登录</a>
            <a :class="{ active: true }" href="javascript:;" v-else>修改密碼</a>
          </nav>
          <!-- 登錄表單 -->
          <LoginForm v-if="activeName === 'login'" @on-switch-form="emitSwitchLoginFormEvent"></LoginForm>
          <!-- 修改密碼表單 -->
          <LoginModifyPass
            v-if="activeName === 'rechange'"
            :accountName="accountName"
            @on-switch-form="emitSwitchLoginFormEvent"
          ></LoginModifyPass>
        </div>
      </section>
    </div>

    <div class="msgDiv" :class="!searchInfo.isShowPage?'msgDivShow':''">
      <div class="msgUrlDiv">需要重新驗證搜索碼，點擊下方前往主站驗證</div>
      <div style="    text-align: center;
    font-size: 0;
    margin-top: 24px;">
        <a :href="searchInfo.mainUrl" class="msgBtn">前往主站</a>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, nextTick, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import LoginHeader from "./components/login-header";
import LoginForm from "./components/login-form";
import LoginModifyPass from "./components/login-modify-pass.vue";
import { checkSearchCode } from "@/api/player";
export default {
  name: "PageLogin",
  components: { LoginHeader, LoginForm, LoginModifyPass },
  setup() {
    let searchInfo = reactive({
      isShowPage: true,
      showTxt: "",
      mainUrl: ""
    });

    onMounted(() => {
      nextTick(() => {
        init();
      });
    });
    const router = useRouter();
    const store = useStore();
    const init = async () => {
      let token = "";
      let geturl = window.location.href;
      let getqyinfo = geturl.split("?");
      if (getqyinfo.length > 1) {
        let getqys = new URLSearchParams("?" + getqyinfo[1]);
        let tk = getqys.get("codetoken");
        if (tk) {
          token = tk;
            console.log("token:",tk);
          store.dispatch("player/codeTokenAction", tk);
        }
      }
      if (!token) {
        var st = store.state.player.codeToken;
        console.log("st:", st);
        if (st) {
          token = st;
        }
      }
      let query={};
				if(token){
					query={codeToken:token};
				}
      let data = await checkSearchCode(query);
      // searchInfo.isShowPage=data.code==0;
      if (data.code != 0) {
        router.push("search");
        // searchInfo.mainUrl=data.msg;
      }
    };
    const activeName = ref("login");
    const accountName = ref("");
    // 存储回跳地址

    const route = useRoute();
    store.dispatch(
      "player/redirectUrlAction",
      route.query.redirectUrl || "/futicai"
    );
    const emitSwitchLoginFormEvent = s => {
      activeName.value = s.switch;
      if (s.hasOwnProperty("accountName")) {
        accountName.value = s.accountName;
      }
    };
    return { activeName, accountName, emitSwitchLoginFormEvent, searchInfo };
  }
};
</script>

<style scoped lang="less">
.login-bg {
  width: 100vw;
  height: 100vh;
  background: url(~@/assets/images/login_bg.jpg) no-repeat center center #0c112e;
  background-size: cover;
  overflow: hidden;
}
.login-section {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 488px;
  position: relative;
  .wrapper {
    width: 380px;
    // height: 380px;
    // background: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    overflow: hidden;
    // transform: translate3d(100px, 0, 0);
    transform: translate(-50%, -50%);
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    nav {
      height: 38px;
      width: 369px;
      // border-bottom: 1px solid #f5f5f5;
      // border-radius: 4px;
      // overflow: hidden;
      // background:#fff;
      display: flex;
      // padding: 0 40px;
      text-align: right;
      align-items: center;
      font-size: 35px;
      text-align: center;
      line-height: 40px;
      font-weight: bolder;
      color: #fff;
      text-shadow: 0 0 30px #000000;
      a {
        flex: 1;
        line-height: 1;
        display: inline-block;
        position: relative;
        &.active {
          color: #fff;
          text-align: center;
          font-weight: bold;
          font-size: 38px;
        }
      }
    }
  }
}

.msgDiv {
  font-size: 33px;
  position: absolute;
  width: 620px;
  padding: 40px 0;
  border-radius: 6px;
  text-align: center;
  top: 88px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  display: none;
}
.msgDivShow {
  display: block !important;
}
.msgUrlDiv {
  font-size: 22px;
  color: #2f2f2f;
}
.msgBtn {
  display: inline-block;
  width: 144px;
  height: 44px;
  line-height: 43px;
  border-radius: 22px;
  font-size: 14px;
  color: #fff;
  // border: 1px solid #4b7977;
  cursor: pointer;
  background-color: red;
}
</style> 
