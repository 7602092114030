<template>
  <div v-if="loading" class="loading"></div>
  <div class="account-box">
    <Form
      ref="formPass"
      class="form"
      :validation-schema="schema"
      v-slot="{ errors }"
      autocomplete="off"
    >
      <div class="form-item">
        <div class="input">
          <i class="iconfont icon-lock"></i>
          <Field
            :class="{ error: errors.oldSafePass }"
            v-model="form.oldSafePass"
            name="oldSafePass"
            type="password"
            placeholder="请输入舊登录密碼"
          />
        </div>
        <div class="error" v-if="errors.oldSafePass">
          <i class="iconfont icon-warning" />
          {{ errors.oldSafePass }}
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <i class="iconfont icon-lock"></i>
          <Field
            :class="{ error: errors.newSafePass }"
            v-model="form.newSafePass"
            name="newSafePass"
            type="password"
            placeholder="请输入新登录密碼"
          />
        </div>
        <div class="error" v-if="errors.newSafePass">
          <i class="iconfont icon-warning" />
          {{ errors.newSafePass }}
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <i class="iconfont icon-lock"></i>
          <Field
            :class="{ error: errors.confirmSafePass }"
            v-model="form.confirmSafePass"
            name="confirmSafePass"
            type="password"
            placeholder="请输入確認登录密碼"
          />
        </div>
        <div class="error" v-if="errors.confirmSafePass">
          <i class="iconfont icon-warning" />
          {{ errors.confirmSafePass }}
        </div>
      </div>
      <a
        @click="rechange"
        href="javascript:;"
        class="btn"
        style="background:#03476a; font-size:14px; border-radius:4px;"
      >修改密碼</a>
    </Form>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { Form, Field } from "vee-validate";
import { userRechangeLoginPassword } from "@/api/player";
import schema from "@/utils/vee-validate-schema";
import Message from "@/components/library/Message";
import md5 from "md5";
export default {
  name: "LoginModifyPass",
  components: { Form, Field },
  props: {
    accountName: {
      type: String,
      default: ""
    }
  },
  emits: ["on-switch-form"],
  setup(props, { emit }) {
    const formPass = ref(null);
    const loading = ref(false);
    const form = reactive({
      oldSafePass: null,
      newSafePass: null,
      confirmSafePass: null
    });

    const mySchema = {
      oldSafePass: schema.oldSafePass,
      newSafePass: schema.newSafePass,
      confirmSafePass: schema.confirmSafePass
    };

    const rechange = async () => {
      const valid = await formPass.value.validate();
      if (valid) {
        loading.value = true;
        await userRechangeLoginPassword({
          loginName: props.accountName,
          password: md5(form.oldSafePass),
          newPassword: md5(form.newSafePass)
        })
          .then(res => {
            loading.value = false;
            if (res.code != 0) {
              Message({
                type: "error",
                text: res.msg || "修改失敗"
              });
            } else {
              Message({
                type: "success",
                text: "修改成功請重新登錄"
              });
            }
            emit("on-switch-form", {
              switch: "login"
            });
          })
          .catch(() => {
            loading.value = false;
            Message({
              type: "error",
              text: res.msg || "修改失敗"
            });
            emit("on-switch-form", "login");
          });
      }
    };

    return {
      form,
      schema: mySchema,
      formPass,
      rechange,
      loading
    };
  }
};
</script>

<style scoped lang="less">
// 账號容器
.account-box {
  padding: 20px 0 0 0;
  .toggle {
    padding: 15px 40px;
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    padding: 0 40px;
    &-item {
      margin-bottom: 28px;
      .input {
        position: relative;
        height: 40px;
        border-radius: 4px;
        overflow:hidden;
        > i {
          width: 40px;
          height: 40px;
          background: #fff;
          color: #cfcdcd;
          position: absolute;
          left: 0px;
          top: 0px;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
        }
        input {
          font-size: 15px;
          padding-left: 48px;
          border: 0px solid #cfcdcd;
          background: #fff;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          width: 85%;
          &.error {
            border-color: @priceColor;
          }
          &.active,
          &:focus {
            // border-color: @xtxColor;
          }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 36px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    .btn {
      display: block;
      width: 100%;
      height: 40px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      background: @xtxColor;
      &.disabled {
        background: #cfcdcd;
      }
    }
  }
  .action {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .url {
      a {
        color: #999;
        margin-left: 10px;
      }
    }
  }
}
</style>
