<template>
<div>
  <div v-if="loading" class="loading"></div>
  <div class="account-box">
    <div class="toggle">
      <a @click="isMsgLogin = false" href="javascript:;" v-if="isMsgLogin">
        <i class="iconfont icon-user"></i> 使用账號登录
      </a>
      <!-- <a @click="isMsgLogin = true" href="javascript:;" v-else>
        <i class="iconfont icon-msg"></i> 使用短信登录
      </a>-->
    </div>
    <Form
      ref="formCom"
      class="form"
      :validation-schema="schema"
      v-slot="{ errors }"
      autocomplete="off"
    >
      <template v-if="!isMsgLogin">
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-user"></i>
            <Field
              :class="{ error: errors.loginName }"
              v-model="form.loginName"
              name="loginName"
              type="text"
              placeholder="请输入登录账號"
            />
          </div>
          <div class="error" v-if="errors.loginName">
            <i class="iconfont icon-warning" />
            {{ errors.loginName }}
          </div>
          <!-- <div class="error"><i class="iconfont icon-warning" />请输入手机號</div> -->
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-lock"></i>
            <Field
              :class="{ error: errors.password }"
              v-model="form.password"
              name="password"
              type="password"
              placeholder="请输入登录密碼"
            />
          </div>
          <div class="error" v-if="errors.password">
            <i class="iconfont icon-warning" />
            {{ errors.password }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-user"></i>
            <Field
              :class="{ error: errors.mobile }"
              v-model="form.mobile"
              name="mobile"
              type="text"
              placeholder="请输入手机號"
            />
          </div>
          <div class="error" v-if="errors.mobile">
            <i class="iconfont icon-warning" />
            {{ errors.mobile }}
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-code"></i>
            <Field
              :class="{ error: errors.smsCode }"
              v-model="form.smsCode"
              name="smsCode"
              type="text"
              placeholder="请输入验证碼"
            />
            <span @click="send()" class="code">{{ time === 0 ? "發送验证碼" : `${time}秒后發送` }}</span>
          </div>
          <div class="error" v-if="errors.smsCode">
            <i class="iconfont icon-warning" />
            {{ errors.smsCode }}
          </div>
        </div>
      </template>
      <a @click="login()" href="javascript:;" class="btn" style="background:#03476a; font-size:14px; border-radius:4px;">登 录</a>
    </Form>
  </div>
  </div>
</template>

<script>
import { onUnmounted, reactive, ref, watch } from "vue";
import { useIntervalFn } from "@vueuse/core";
import { Form, Field } from "vee-validate";
import {
  requestAccountLogin,
  userMobileLoginMsg,
  userMobileLogin
} from "@/api/player";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import schema from "@/utils/vee-validate-schema";
import Message from "@/components/library/Message";
import md5 from "md5";
export default {
  name: "LoginForm",
  components: { Form, Field },
  emits: ["on-switch-form"],
  setup(props, { emit }) {
    const isMsgLogin = ref(false);
    const loading = ref(false);
    const form = reactive({
      loginName: null,
      password: null,
      mobile: null,
      smsCode: null
    });

    const mySchema = {
      loginName: schema.loginName,
      password: schema.password,
      mobile: schema.mobile,
      smsCode: schema.smsCode
    };

    const formCom = ref(null);
    watch(isMsgLogin, () => {
      form.loginName = null;
      form.password = null;
      form.mobile = null;
      form.smsCode = null;
      formCom.value.resetForm();
    });

    const store = useStore();
    const router = useRouter();
    const login = async () => {
      const valid = await formCom.value.validate();
      if (valid) {
        loading.value = true;
        let data = null;
        try {
          if (!isMsgLogin.value) {
            const { loginName, password } = form;
            data = await requestAccountLogin({
              loginName,
              password: md5(password)
            });
          } else {
            const { mobile, smsCode } = form;
            data = await userMobileLogin({ mobile, smsCode });
          }
          loading.value = false;
          if (data.code != 0) {
            if (data.code == 501) {
              emit("on-switch-form", {
                switch: "rechange",
                accountName: form.loginName
              });
            }
            Message({
              type: "error",
              text: data.msg || "登录失败"
            });
            return;
          }
        } catch (e) {
          loading.value = false;
          Message({
            type: "error",
            text: e.response.data.msg || "登录失败"
          });
        }
        const {
          accountId,
          nickName,
          portrait,
          mobile,
          lastLoginIp,
          amount,
          totalDeposit,
          curPlatCode,
          totalWithdraw
        } = data.res.info;
        console.log('totalWithdraw----------',totalWithdraw)
        store.dispatch("player/authTokenAction", data.res.access_token);
        store.dispatch("player/playInfoAction", {
          accountId,
          nickName,
          portrait,
          mobile,
          lastLoginIp,
          amount,
          totalDeposit,
          totalWithdraw
        });
        if (curPlatCode && curPlatCode.split(",").length > 0) {
          store.dispatch(
            "app/setCurrentPlatCodeAction",
            curPlatCode.split(",")[0]
          );
        }
        router.push(store.state.player.redirectUrl);
      }
    };

    const time = ref(0);
    const { pause, resume } = useIntervalFn(
      () => {
        time.value--;
        if (time.value <= 0) {
          pause();
        }
      },
      1000,
      { immediate: false }
    );
    onUnmounted(() => {
      pause();
    });

    const send = async () => {
      const valid = mySchema.mobile(form.mobile);
      if (valid === true) {
        // 通过
        if (time.value === 0) {
          // 没有倒计时才可以發送
          await userMobileLoginMsg(form.mobile);
          Message({ type: "success", text: "發送成功" });
          time.value = 60;
          resume();
        }
      } else {
        formCom.value.setFieldError("mobile", valid);
      }
    };
    return {
      isMsgLogin,
      form,
      schema: mySchema,
      formCom,
      login,
      send,
      time,
      loading
    };
  }
};
</script>

<style scoped lang="less">
// 账號容器
.account-box {
  // padding: 10px 0 0 0;
  .toggle {
    padding: 15px 40px;
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    padding: 0 40px;
    &-item {
      margin-bottom: 28px;
      .input {
        font-size:15px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        height: 40px;
        > i {
          width: 40px;
          height: 40px;
          background: #fff;
          color: #ccc;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
        }
        input {
          font-size:15px;
          padding-left: 48px;
          border: 0px solid #cfcdcd;
          background:#fff;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          width: 85%;
          overflow: hidden;
          &.error {
            // border-color: @priceColor;
            color:@priceColor;
          }
          // &.active,
          // &:focus {
          //   border-color: @xtxColor;
          // }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 36px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 14px;
        line-height: 30px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        // color: #069;
      }
    }
    .btn {
      display: block;
      width: 300px;
      height: 40px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      background: @xtxColor;
      &.disabled {
        background: #cfcdcd;
      }
    }
  }
  .action {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .url {
      a {
        color: #999;
        margin-left: 10px;
      }
    }
  }
}
</style>
