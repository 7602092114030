<template>
  <header class="login-header">
    <div class="container">
      <h1 class="logo"><span>889</span></h1>
      <!-- <h3 class="sub"><slot /></h3> -->
    </div>
  </header>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>

<style scoped lang='less'>
.login-header {
  // background: #fff;
  // border-bottom: 1px solid #e4e4e4;
  .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .logo {
    width: 100%;
    margin:0 auto;
    text-align: left;
    padding: 50px;
    span {
      display: block;
      height: 80px;
      width: 200px;
      text-indent: -9999px;
      background: url(~@/assets/images/logo.png) no-repeat 0 0;
      background-size: 100% 100%;
    }
  }
  .sub {
    flex: 1;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 38px;
    margin-left: 20px;
    color: #666;
  }
  .entry {
    width: 120px;
    margin-bottom: 38px;
    font-size: 16px;
    i {
      font-size: 14px;
      color: @xtxColor;
      letter-spacing: -5px;
    }
  }
}
</style>
